import React, { FC, useEffect, useRef, useState } from 'react';
import QRCode from 'qrcode';
import { useTranslation } from 'react-i18next';
import styleObj from './home.module.less';
import Layout, { LayoutRef } from '../../../components/Layout/Layout';
import Footer from '../../../components/Footer/Footer';
import Download from '../../../components/Download/Download';
import { useChangeOsLanguage } from '../../../hooks';
import { useHistory, useLocation } from 'react-router-dom';
import { getApi, postApi, sendPoint } from '../../../util/tool';
import Img from '../../../components/Img';
import { PAY_CARD } from '../../../const';
import { useLottie } from 'lottie-react';
import startData from './assets/start.json';
import { useAuth } from '../../../stores/AuthContext';

const imgArr: Array<string> = [
  'https://auto.omicdn.com/v1/images/eyJpZCI6IkhXRkJRQkdDQ1pBVjNQTUxBUFk0NkpPRUlMU1BBMiIsInciOjI4ODAsImgiOjE1ODAsImQiOjAsIm10IjoiaW1hZ2UvanBlZyIsImRoIjoxMTg2NTUyNjkwOTg1NTcwODMwMSwiYWIiOjB9?format=originalOFGHLERTH',
  'https://auto.omicdn.com/v1/images/eyJpZCI6Ik4yMkJCSFFERkdDRFgyQVhTS1g2WllKT0NWRDVWRSIsInciOjI4ODAsImgiOjE1ODAsImQiOjAsIm10IjoiaW1hZ2UvanBlZyIsImRoIjo2MzE1MzY4MjA2NDQwMzU1MTQ1LCJhYiI6MH0?format=originalOFGHLERTH',
  'https://auto.omicdn.com/v1/images/eyJpZCI6IlJWQU9PUDNMU0tYQUtTQUxYSlFZVUFWNlhGRFJQVSIsInciOjI4ODAsImgiOjE1ODAsImQiOjAsIm10IjoiaW1hZ2UvanBlZyIsImRoIjoxNTY1Mjk0MTgxNDM0MTk0Njc4NywiYWIiOjB9?format=originalOFGHLERTH',
  'https://auto.omicdn.com/v1/images/eyJpZCI6IlFOUEkyNkJPNkJUN1JCN0hZUzQ1WUhEQVZQNzZDSSIsInciOjI4ODAsImgiOjE1ODAsImQiOjAsIm10IjoiaW1hZ2UvanBlZyIsImRoIjo1NDIzOTI3ODgwNjQ2NDcwNTQxLCJhYiI6MH0?format=originalOFGHLERTH',
  'https://auto.omicdn.com/v1/images/eyJpZCI6IkZaNlBHWFlJR0ZWWTNYV0hXR1VVT0I0TU8yM1dNSSIsInciOjI4ODAsImgiOjE1ODAsImQiOjAsIm10IjoiaW1hZ2UvanBlZyIsImRoIjoxMDc2OTY0Mjk1MDczMTA2MjczMywiYWIiOjB9?format=originalOFGHLERTH',
  'https://auto.omicdn.com/v1/images/eyJpZCI6IlU1VlFNTTJDWDM3VDdIVk5CUVBOTzNKSkRSN01TMiIsInciOjI4ODAsImgiOjE1ODAsImQiOjAsIm10IjoiaW1hZ2UvanBlZyIsImRoIjo4NDE1MzIyMzcwNTM1NzMxMTg3LCJhYiI6MH0?format=originalOFGHLERTH',
  'https://auto.omicdn.com/v1/images/eyJpZCI6Ik9USUE0V0pRR1E2MzdMQ1JQT05FTjVZN1ZDUVgyWSIsInciOjY1MCwiaCI6MjM0LCJkIjowLCJtdCI6ImltYWdlL2pwZWciLCJkaCI6NDk5MTM5NzE1OTAzNjU4NDI2MSwiYWIiOjB9?format=originalOFGHLERTH',
  'https://auto.omicdn.com/v1/images/eyJpZCI6IjNSTUNDRVU1Q01INFJGSFBERUpBWlVSNU1PTEJFNyIsInciOjY1MCwiaCI6NTMzLCJkIjowLCJtdCI6ImltYWdlL2pwZWciLCJkaCI6NDk3MjMzMTYyNzQxMDk0ODU0OCwiYWIiOjB9?format=originalOFGHLERTH',
];

const vipObj: any = {
  vip: 0,
  seeWhoLikedMe: 1,
  supreme: 2,
};

const Home: FC = () => {
  /* 进入官网首页的时候，将系统语言同步到选择的语言 */
  const { t, i18n: { changeLanguage } } = useTranslation();
  const [oSLanguage] = useChangeOsLanguage();
  useEffect(() => {
    changeLanguage(oSLanguage).then();
    window.localStorage.setItem('lan', oSLanguage);
  }, [oSLanguage]);

  const history = useHistory();
  const location = useLocation();
  const { hash } = location;
  const { location: { search } } = useHistory();
  const [showTwoCode, setShowTwoCode] = useState(false); // 二维码登录弹窗
  const [codeImg, setCodeImg] = useState('');
  const [count, setCount] = useState<number>(3); // 倒计时
  const [gotAccessToken, setGotAccessToken] = useState(false);
  const [vipType, setVipType] = useState<Array<number>>([]); // 会员种类 0 1 2
  const [isChangingImg, setIsChangingImg] = useState<boolean>(false);  
   const { logged, logout, login } = useAuth();
  
  const layoutRef = useRef<LayoutRef>(null);
  const centerDiv = useRef<HTMLDivElement | null>(null);
  const homeDiv = useRef<HTMLDivElement | null>(null);
  const startDiv = useRef<HTMLDivElement | null>(null);
  const imgArrRef = [
    useRef<HTMLImageElement | null>(null),
    useRef<HTMLImageElement | null>(null),
    useRef<HTMLImageElement | null>(null),
    useRef<HTMLImageElement | null>(null),
    useRef<HTMLImageElement | null>(null),
    useRef<HTMLImageElement | null>(null),
  ];
  const button = useRef<HTMLDivElement | null>(null);
  const title = useRef<HTMLDivElement | null>(null);
  const processArr = [
    useRef<HTMLSpanElement | null>(null),
    useRef<HTMLSpanElement | null>(null),
    useRef<HTMLSpanElement | null>(null),
  ];
  const processUnderArr = [
    useRef<HTMLSpanElement | null>(null),
    useRef<HTMLSpanElement | null>(null),
    useRef<HTMLSpanElement | null>(null),
  ];
  const processWrapper = useRef<HTMLDivElement | null>(null);
  const contentArr = [
    useRef<HTMLDivElement | null>(null),
    useRef<HTMLDivElement | null>(null),
    useRef<HTMLDivElement | null>(null),
  ];
  const [showIndex, setShowIndex] = useState<number>(-1);
  const [start, setStart] = useState<boolean>(false);
  const [backgroundColor, setBackgroundColor] = useState<string>('');
  const cardData = [
    {
      cardContent: t('home.cardContent1'),
      cardUser: t('home.cardUser1'),
    },
    {
      cardContent: t('home.cardContent2'),
      cardUser: t('home.cardUser2'),
    },
    {
      cardContent: t('home.cardContent3'),
      cardUser: t('home.cardUser3'),
    },
  ];

  const init = async () => {
    try {
      const res2 = await getApi('/web/privileges');
      if (res2.meta.code === 401000) { // 未登录
        logout()
      } else { // 登录了
        /* 设置登录状态 */
        login()
        /* 设置会员信息 */
        if (res2?.data?.simplePrivileges && Array.isArray(res2?.data?.simplePrivileges)) {
          const resVipType = res2.data.simplePrivileges.map((item: string) => vipObj[item]);
          setVipType(resVipType);
        } else {
          setVipType([]);
        }
        /* 获取到会员信息后打点 */
        sendPoint({
          event_name: 'omi_web_website_home_page.pageview',
          status: 'logged_in',
        });
      }
    } catch (e) {
      setVipType([]);
        logout()
    }
  };

  const webLogin = async (authToken: any) => {
    try {
      // 扫描二维码后，就可以通过 authToken 换取 accessToken 和 currencyCode 了
      const res = await postApi('/web/access-token', { authToken });
      if (res.accessToken) {
        localStorage.setItem('accessToken', res.accessToken); // 存到本地
        localStorage.setItem('currencyCode', res.extra.currencyCode);
        await init();
        setShowTwoCode(false); // 隐藏二维码
        setCodeImg('');
        setGotAccessToken(true); // 换取accessToken后的3s后会自动跳转
        setTimeout(() => {
          setGotAccessToken(false);
        }, 6000);
      } else if (res.code === 401000) {
        setTimeout(async () => {
          if (showTwoCodeRef.current) { // 如果二维码没被关闭，则继续调用接口
            await webLogin(authToken);
          }
        }, 2000);
      } else if (res.code === 40045) {
        // 放弃等待登录结果
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    let interval: any;
    let curIndex = 0;

    if (isChangingImg) {
      imgArrRef.forEach((item, index) => {
        const img = item.current!;
        if (index === curIndex) {
          img.style.opacity = '1';
          img.style.zIndex = '-5';
        } else {
          img.style.opacity = '0';
          img.style.zIndex = '-50';
        }
      });
      interval = setInterval(() => {
        if (curIndex > 4) {
          curIndex = 0;
        } else {
          curIndex++;
        }
        imgArrRef.forEach((item, index) => {
          if (index === curIndex) {
                        item.current!.style.opacity = '1';
          } else {
                        item.current!.style.opacity = '0';
          }
        });
      }, 3000);
    }
    return () => clearInterval(interval);
  }, [isChangingImg]);

  useEffect(() => {
    if (hash) { // 短信链接跳进来的
      history.push({
        pathname: '/vip',
        state: {
          authToken: hash.substring(1),
        },
      });
    }
  }, [hash, search]);

  const initDataAnimation = async () => {
    sendPoint({
      event_name: 'omi_web_website_home_page.pageview',
      status: 'un_logged_in',
    });
  };

  useEffect(() => {
    imgArr.forEach((src) => {
      const img = new Image();
      img.src = src;
    });
    initDataAnimation();
  }, []);

  useEffect(() => {
    let timer: NodeJS.Timer;
    if (logged && gotAccessToken) {
      timer = setTimeout(() => {
        if (count <= 0) {
          // 自动跳转也需要打点
          sendPoint({
            event_name: 'omi_web_website_home_page_button.click',
            status: 'logged_in',
          });
          if (vipType.includes(1) || vipType.includes(2)) {
            history.push({ pathname: '/vip', state: { linkCurrentVip: 2 } });
            console.log('自动跳转');
          } else {
            history.push({ pathname: '/vip', state: { linkCurrentVip: 1 } });
            console.log('自动跳转');
          }
          clearTimeout(timer);
        } else {
          setCount((count) => count - 1);
        }
      }, 1000);
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [logged, count]);

  useEffect(() => {
    const homeDom = homeDiv.current; // 用于绑定滚动事件
    const menuDom = layoutRef?.current?.menuDom!; // 导航文字（PC）
    const languageDom = layoutRef?.current?.languageDom!; // 小地球（PC）

    const handler = () => {
      const centerDom = centerDiv.current!;
      if (centerDom) {
        const { top, bottom } = centerDom.getBoundingClientRect();
        // 控制渐进反色
        if (top < 27) {
          menuDom && (menuDom.style.height = '0'); // 导航文字
          languageDom && (languageDom.style.height = '0'); // 小地球
        } else if (top > 59) {
          menuDom && (menuDom.style.height = `${25}px`);
          languageDom && (languageDom.style.height = `${19}px`);
        } else {
          menuDom && (menuDom.style.height = `${top - 34}px`);
          languageDom && (languageDom.style.height = `${top - 39}px`);
        }

        // 控制头部背景色渐变
        if (top >= 0 && top <= 71) {
          setBackgroundColor(`rgba(255,255,255,${(71 - top) / 71})`);
        } else if (top < 0) {
          setBackgroundColor('rgb(255,255,255)');
        } else {
          setBackgroundColor('');
        }

        // 控制动画开始
        if (bottom < 1.4 * window.innerHeight) {
          setStart(true);
        }
      }
    };

    homeDom && homeDom.addEventListener('scroll', handler); // 绑定滚屏事件
    let timeout1: any; let
      timeout2: any;
    if (showIndex !== -1) {
      showCenterContent(showIndex);
      if (showIndex >= 2) {
        timeout1 = setTimeout(() => {
          setShowIndex(0);
        }, 4000);
      } else {
        timeout2 = setTimeout(() => {
          setShowIndex((val) => val + 1);
        }, 4000);
      }
    }

    return () => {
      homeDom && homeDom.removeEventListener('scroll', handler);
      clearTimeout(timeout1);
      clearTimeout(timeout2);
    };
  }, [showIndex]);

  useEffect(() => {
    if (start) {
            processWrapper.current!.style.display = 'flex';
            processWrapper.current!.classList.add('animate__fadeInUpBig');
            setTimeout(() => {
              setShowIndex(0);
            }, 1000);
    }
  }, [start]);

  const showCenterContent = (targetIndex: number) => {
    contentArr.forEach((content, index) => {
      if (index === targetIndex) {
                content.current!.style.opacity = '1';
      } else {
                content.current!.style.opacity = '0';
      }
    });
    processArr.forEach((process, index) => {
      if (index === targetIndex) {
                processUnderArr[index].current!.style.backgroundColor = '#212121';
                process.current!.style.transition = '0s width';
                process.current!.style.width = '0';
                setTimeout(() => {
                    process.current!.style.transition = '3900ms width linear';
                    process.current!.style.width = '100%';
                }, 100);
      } else if (index > targetIndex) {
                processUnderArr[index].current!.style.backgroundColor = '#212121';
                process.current!.style.transition = '0s width';
                process.current!.style.width = '0';
      } else {
                process.current!.style.transition = '0s width';
                process.current!.style.width = '100%';
                processUnderArr[index].current!.style.backgroundColor = '#16ec9b';
      }
    });
  };

  const setAnimation = (dom: HTMLElement, className: string, delay: number = 0): void => {
    setTimeout(() => {
      dom.style.visibility = 'visible';
      dom.classList.add(className);
    }, delay);
  };

  const startAnimation = (): Promise<void> => {
    return new Promise<void>((resolve) => {
      setAnimation(title.current!, 'animate__fadeInRight');
      setAnimation(button.current!, 'animate__fadeInUp');
      setAnimation(layoutRef.current!.headerDiv!, 'animate__fadeInDown');
      setTimeout(() => {
        resolve();
      }, 1100);
    });
  };

  const showTwoCodeRef = useRef(showTwoCode);
  useEffect(() => {
    showTwoCodeRef.current = showTwoCode;
  }, [showTwoCode]);

  const renderButton = () => {
    if (!logged) { // 如果未登录，展示的是登录按钮
      return (
        <div
          className={styleObj.button}
          onClick={
                        // PC端的登录 → 展示二维码
                        async () => {
                          const { authToken } = await postApi('/web/auth-token', { authType: 'qrCode' });
                          const imgStr = await QRCode.toDataURL(authToken, { width: 400, height: 400 });
                          setCodeImg(imgStr);
                          setShowTwoCode(true);
                          webLogin(authToken);
                        }
                    }
        >
          {t('h5.OMI_PHONE_WEBSITE_HOME_PAGE_LOGIN')}
          <div className={styleObj.save1}>
            <div className={styleObj.save1_text1}>{t('h5.OMI_PHONE_WEBSITE_HOME_PAGE_SAVE')}</div>
            <div className={styleObj.save1_text2}>50<span>%</span></div>
          </div>
        </div>
      );
    } else {
      return (
        <div
          className={styleObj.button_one}
          onClick={
                        () => {
                          if (vipType.includes(1) || vipType.includes(2)) {
                            history.push({ pathname: '/vip', state: { linkCurrentVip: 2 } });
                          } else {
                            history.push({ pathname: '/vip', state: { linkCurrentVip: 1 } });
                          }
                        }
                    }
        >
          {
                        gotAccessToken ?
                          `${t('h5.OMI_PHONE_WEBSITE_HOME_PAGE_CONTINUE')} (${count})`
                          :
                          `${t('h5.OMI_PHONE_WEBSITE_HOME_PAGE_CONTINUE')}`
                    }
          <div className={styleObj.save1}>
            <div className={styleObj.save1_text1}>{t('h5.OMI_PHONE_WEBSITE_HOME_PAGE_SAVE')}</div>
            <div className={styleObj.save1_text2}>50<span>%</span></div>
          </div>
        </div>
      );
    }
  };

  const startStep = (): Promise<void> => {
    let start = 0;
    let previousTimeStamp = 0;
    return new Promise<void>((resolve) => {
      const step = (timestamp: number): void => {
        if (start === 0) start = timestamp;
        const elapsed = timestamp - start;
        if (previousTimeStamp !== timestamp) {
          const count = Math.min(0.1 * elapsed, 100);
          startDiv.current && (startDiv.current.style.height = `${100 - count}vh`);
        }
        if (elapsed < 1000) {
          previousTimeStamp = timestamp;
          window.requestAnimationFrame(step);
        } else { // 结束了
          resolve();
        }
      };
      window.requestAnimationFrame(step);
    });
  };

  const { View } = useLottie({
    style: { width: '10vw' },
    animationData: startData,
    loop: false,
    onComplete: async () => {
            imgArrRef[0].current!.style.transform = 'scale(1)';
            await startStep();
            await startAnimation();
            setIsChangingImg(true);
    },
  });

  return (
    <Layout
      ref={layoutRef}
      headerStyle={{ backgroundColor, visibility: 'hidden' }}
      isHome
    >
      <div
        ref={homeDiv}
        className={styleObj.home}
        style={{ height: window.innerHeight }}
      >

        {/* 开场幕布 */}
        {
          <div
            className={styleObj.start}
            ref={startDiv}
          >
            {View}
          </div>}

        {/* 第一屏 */}
        <div className={styleObj.top}>
          {
                        imgArr.map((src, index) => (
                          <img
                            key={index}
                            src={src}
                            ref={imgArrRef[index]}
                            className={styleObj.img}
                          />
                        ))
                    }
          <div className={styleObj.title_area}>
            <div
              ref={title}
              className="animate__animated"
              dangerouslySetInnerHTML={{ __html: t('home.title') }}
            />
          </div>
          <div
            ref={button}
            className={`${styleObj.button_wrapper} animate__animated`}
          >
            <div onClick={
                            () => {
                              sendPoint({
                                event_name: 'omi_web_website_home_page_button.click',
                                status: logged ? 'logged_in' : 'un_logged_in',
                              });
                            }
                        }
            >
              {renderButton()}
            </div>
            <div className={styleObj.button_title}>
              {t('home.OMI_PHONE_WEBSITE_HOME_PAGE_PURCHASE_MORE_METHOD')}
            </div>
            <div className={styleObj.pay_card_wrapper}>
              {
                                logged &&
                                Array.isArray(PAY_CARD[(localStorage.getItem('currencyCode')!)]) &&
                                PAY_CARD[(localStorage.getItem('currencyCode')!)].map((src: any) => (<Img
                                  className={styleObj.pay_card}
                                  src={src}
                                />))
                            }
            </div>
          </div>
        </div>

        {/* 第二屏 */}
        <div
          className={styleObj.center}
          ref={centerDiv}
        >
          <div className={styleObj.content}>
            <div className={styleObj.text_wrapper}>
              {
                                cardData.map(({ cardContent, cardUser }, index) => (
                                  <div key={index} ref={contentArr[index]}>
                                    <div>
                                      <img
                                        src="https://auto.omicdn.com/v1/images/eyJpZCI6IkJYWUtRVzNVSzZNNUlQSjNIVllMNlhONVNYSFRKUCIsInciOjEyOCwiaCI6MTM2LCJkIjowLCJtdCI6ImltYWdlL2pwZWciLCJkaCI6MTM4NDg1NjAyNjU0NjE3NjAsImFiIjowfQ?format=originalOFGHLERTH"
                                        alt=""
                                      />
                                    </div>
                                    <div>{cardContent}</div>
                                    <div>{cardUser}</div>
                                  </div>
                                ))
                            }
            </div>
            <div
              ref={processWrapper}
              className={`${styleObj.process_wrapper} animate__animated`}
            >
              {
                                Array.from({ length: 3 }).map((_, index) => (
                                  <div
                                    key={index}
                                    onClick={() => setShowIndex(index)}
                                  >
                                    <span ref={processUnderArr[index]} />
                                    <span ref={processArr[index]} />
                                  </div>
                                ))
                            }
            </div>
          </div>
        </div>

        {/* 第三屏 */}
        <Download />
        <Footer />

        {/* 二维码弹窗 */}
        <div className={styleObj.modal} style={{ display: showTwoCode ? 'block' : 'none' }}>
          <div className={styleObj.two_code_wrapper}>
            <img className={styleObj.code_img} src={codeImg} alt="" />
            <div className={styleObj.code_title}>{t('home.OMI_WEB_WEBSITE_QR_CODE_POPUP_TITLE')}</div>
            <div className={styleObj.code_content}>{t('home.OMI_WEB_WEBSITE_QR_CODE_POPUP_SUBTITLE')}</div>
            <div
              className={styleObj.close_button}
              onClick={
                                () => {
                                  setShowTwoCode(false);
                                  setCodeImg('');
                                }
                            }
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Home;
