import React, { FC, lazy, Suspense, useEffect, useState } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Home from './pages/Home/Home';
import { ToastProvider  } from './stores/ToastContext';
import { AuthProvider  } from './stores/AuthContext';


const Features = lazy(() => import('./pages/Features/Features'));
const Toolkit = lazy(() => import('./pages/Toolkit/Toolkit'));
const Subscription = lazy(() => import('./pages/Subscription/Subscription'));
const WhyOmi = lazy(() => import('./pages/WhyOmi/WhyOmi'));
const Press = lazy(() => import('./pages/Press/Press'));
const Article = lazy(() => import('./pages/Article/Article'));
const Support = lazy(() => import('./pages/Support/Support'));
const Apply = lazy(() => import('./pages/Apply/Apply'));
const Company = lazy(() => import('./pages/Company/Company'));
const Guideline = lazy(() => import('./pages/TextPages/Guideline'));
const Help = lazy(() => import('./pages/TextPages/Help'));
const Terms = lazy(() => import('./pages/TextPages/Terms'));
const Service = lazy(() => import('./pages/TextPages/Service'));
const Privacy = lazy(() => import('./pages/TextPages/Privacy'));
const SafetyTips = lazy(() => import('./pages/TextPages/SafetyTips'));
const Share = lazy(() => import('./pages/Share/Share'));
const AntiFraud = lazy(() => import('./pages/AntiFraud/AntiFraud'));
const Vip = lazy(() => import('./pages/Vip'));
// const Test = lazy(() => import('./pages/Test/Test'));
const PaySuccess = lazy(() => import('./pages/PaySuccess/PaySuccess'));
const PayError = lazy(() => import('./pages/PayError/PayError'));
const PayPending = lazy(() => import('./pages/PayPending/PayPending'));

const App: FC = () => {
  const [normal, setNormal] = useState(true);

  useEffect(() => {
    const fn = () => {
      if (window.orientation == 90 || window.orientation == -90) {
        // 横屏
        setTimeout(() => {
          if (window.innerWidth > 1.6 * window.innerHeight && window.innerHeight < 500 && window.innerWidth < 900) {
            setNormal(false);
          }
        }, 100);
      } else {
        setNormal(true);
      }
    };
    window.addEventListener('orientationchange', fn);
    if (window.innerWidth > 1.6 * window.innerHeight && window.innerHeight < 500 && window.innerWidth < 900) {
      setNormal(false);
    }
    return () => {
      window.removeEventListener('orientationchange', fn);
    };
  }, []);

  if (normal) {
    return (
      <ToastProvider>
        <AuthProvider>
        <BrowserRouter>
          <Switch>
            <Route path="/" component={Home} exact />
            <Route
              path="/features"
              exact
              render={() => <Suspense><Features /></Suspense>}
            />
            <Route
              path="/features/toolkit"
              exact
              render={() => <Suspense><Toolkit /></Suspense>}
            />
            <Route
              path="/features/subscription"
              exact
              render={() => <Suspense><Subscription /></Suspense>}
            />
            <Route
              path="/why"
              exact
              render={() => <Suspense><WhyOmi /></Suspense>}
            />
            <Route
              path="/press"
              exact
              render={() => <Suspense><Press /></Suspense>}
            />
            <Route
              path="/press/:id"
              exact
              render={() => <Suspense><Article /></Suspense>}
            />
            <Route
              path="/support"
              exact
              render={() => <Suspense><Support /></Suspense>}
            />
            <Route
              path="/apply/:job?/:country?"
              exact
              render={() => <Suspense><Apply /></Suspense>}
            />
            <Route
              path="/company/:position?"
              exact
              render={() => <Suspense><Company /></Suspense>}
            />
            <Route
              path="/norms/:language?"
              render={() => <Suspense><Guideline /></Suspense>}
            />
            <Route
              path="/help"
              exact
              render={() => <Suspense><Help /></Suspense>}
            />
            <Route
              path="/terms/:language?"
              exact
              render={() => <Suspense><Terms /></Suspense>}
            />
            <Route
              path="/privacy/:language?"
              exact
              render={() => <Suspense><Privacy /></Suspense>}
            />
            <Route
              path="/safety_tips"
              exact
              render={() => <Suspense><SafetyTips /></Suspense>}
            />
            {/* 分享页 */}
            <Route
              path="/share"
              exact
              render={() => <Suspense><Share /></Suspense>}
            />
            {/* 反诈页 */}
            <Route
              path="/anti_fraud"
              exact
              render={() => <Suspense><AntiFraud /></Suspense>}
            />
            {/* 会员购买页 */}
            <Route
              path="/vip"
              exact
              render={() => <Suspense><Vip /></Suspense>}
            />
            {/* 支付结果页：成功 */}
            <Route
              path="/pay_success"
              exact
              render={() => <Suspense><PaySuccess /></Suspense>}
            />
            {/* 支付结果页：失败 */}
            <Route
              path="/pay_error"
              exact
              render={() => <Suspense><PayError /></Suspense>}
            />
            {/* 支付结果页：等待 */}
            <Route
              path="/pay_pending"
              exact
              render={() => <Suspense><PayPending /></Suspense>}
            />
            {/* 服务者协议 */}
            <Route
              path="/service"
              exact
              render={() => <Suspense><Service /></Suspense>}
            />
          </Switch>
        </BrowserRouter>
        </AuthProvider>
      </ToastProvider>

    );
  } else {
    return (
      <div style={{
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      >
        <div style={{ fontSize: 60, lineHeight: 1.3 }}>🙃</div>
        <h3 style={{ fontWeight: 600, fontSize: 24, marginBottom: 5 }}>Catch Us On The Flip Side</h3>
        <div style={{ color: '#505965', fontSize: 14 }}>Enjoy matching up from a portrait view.</div>
      </div>
    );
  }
};
export default App;
